.mainPage {  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(3, 3, 4);
  overflow: hidden;
}

.title {
  color: white;
  font-size: 7vw;
}

.header {
  position: fixed;
  left: 2vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5vw;
  background-color: rgba(8, 14, 28, 0.75);
  color: white;
  margin-top: 1vw;
  border-radius: 1vw;
  font-size: 1.75vw;
  padding: 0.75vw;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17.5vw;
  margin-top: 5vw;
  width: 100%;
  color: white;
  font-size: 1.75vw;
  padding: 1vw;
}

/* width */
::-webkit-scrollbar { 
  height: 2vw;
  width: 0.5vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(3, 3, 4);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2c303d;
  border-radius: 0.5vw;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.youtubeIcon, .discordIcon, .githubIcon, .fivemIcon, .storeIcon {
  font-size: 1.75vw;
  color: rgb(219, 219, 219);
  padding: 0.5vw;
  /* background-color: white; */
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5vw;
  transition: 0.25s;
}

.youtubeIcon:hover, .discordIcon:hover, .githubIcon:hover, .fivemIcon:hover, .storeIcon:hover {
  color: rgb(219, 219, 219);
  background-color: rgb(33, 98, 188);
  transition: 0.25s;
  cursor: pointer;
}

.fivemIcon {
  font-size: 1.5vw;
}  

.orbit {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  color: white;
  font-size: 6vw;
}

.subTitle {
  color: white;
  font-size: 3.5vw;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  color: white;
  font-size: 2.5vw;
  padding: 1vw;
  text-align: center;
  font-weight: bold;
}

.about p {
  font-weight: normal;
  padding: 0.5vw;
  font-size: 1.75vw;
  color: rgb(184, 201, 203);
  word-break: break-word;
}

.videos {
  padding: 1.5vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 70vw;
  height: fit-content;
  gap: 1vw;
}

.video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  position: relative;
}

.needHelp {
  position: fixed;
  top: 1vw;
  right: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(8, 14, 28, 0.75);
  border-radius: 1vw;
  color: white;
  font-size: 1vw;
  padding: 1vw;
  text-align: center;
}

.needHelpButton {
  color: white;
  padding: 0.5vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}